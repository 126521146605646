import store from "@/store";

export const loginGuards = (to, from, next) => {
        if (!store.getters.isAuthenticated) {
            next();
        } else if (store.getters.user.role === "L0") {
            next("/trustee/dashboard");
        } else if (store.getters.user.role === "L1" || store.getters.user.role === "L4") {
            next("/admin/dashboard");
        } else if (store.getters.user.role === "L2") {
            next("/employee/dashboard");
        }
        else if (store.getters.user.role === "L3") {
            next("/hr/dashboard");
        } 
        else {
            next();
        }
};

export const trusteeGuards = (to, from, next) => {
    if(store.getters.isAuthenticated){
        if (store.getters.user.role === 'L0') {
            next();
        } else {
            next('/');
        }
    }
    else{
        next('/');
    }
};

export const adminGuards = (to, from, next) => {
    if(store.getters.isAuthenticated){
        if (store.getters.user.role === 'L1' || store.getters.user.role === "L4") {
            next();
        } else {
            next('/');
        }
    }
    else{
        next('/');
    }
};

export const hrGuards = (to, from, next) => {
    if(store.getters.isAuthenticated){
        if (store.getters.user.role === 'L3') {
            next();
        } else {
            next('/');
        }
    }
    else{
        next('/');
    }
};

export const employeeGuards = (to, from, next) => {
    if(store.getters.isAuthenticated){
        if (store.getters.user.role === 'L2') {
            next();
        } else {
            next('/');
        }
    }
    else{
        next('/');
    }
};