<!-- TreeChart.vue -->
<template>
    <div>
      <ChartComp :data="chartData" />
    </div>
  </template>
  
  <script>
  import ChartComp from "../components/ChartComp.vue";
  
  export default {
    name: "TreeChart",
    components: {
      ChartComp,
    },
    data() {
      return {
        chartData: {
          name: "College",
          children: [
            {
              name: "Head",
            },
            {
              name: "Principles",
              children: [
                { name: "Principle 1" },
                { name: "Principle 2" },
                { name: "Principle 3" },
                { name: "Principle 4" },
              ],
            },
            {
              name: "Staffs",
              children: [
                { name: "Staff 1" },
                { name: "Staff 2" },
                { name: "Staff 3" },
                { name: "Staff 4" },
                { name: "Staff 5" },
                { name: "Staff 6" },
              ],
            },
            {
              name: "Students",
              children: [
                { name: "Student 1" },
                { name: "Student 2" },
                { name: "Student 3" },
                { name: "Student 4" },
                { name: "Student 5" },
                { name: "Student 6" },
                { name: "Student 7" },
                { name: "Student 8" },
                { name: "Student 9" },
                { name: "Student 10" },
              ],
            },
          ],
        },
      };
    },
  };
  </script>
  
  <style>
  </style>
  