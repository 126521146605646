import { createStore } from "vuex";
import axios from "axios";
// import router from "@/router";
import createPersistedState from "vuex-persistedstate";

// import SecureLS from 'secure-ls';
// const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    token: null,
    user: null,
    // otpVerificationError: null,
    isAuthenticated: false,
    // isOpenPopUp: false,
  },

  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    token: (state) => state.token,
  },

  mutations: {
    setLoggedInInfo(state, data) {
      state.isAuthenticated = true;
      state.token = data.token;
      state.user = data.employee_details;
    },

    setLogout(state) {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
    },

    setPopupInfo(state, data) {
      state.user.is_hr_acknowledged = data;
    },

    setProfileImage(state, data){
      state.user.profile_photo = data;
      state.user.employee_profile_picture = data;
    }
    // SET_isAuthenticated(state, isAuthenticated) {
    //   state.isAuthenticated = isAuthenticated;
    // },
    // SET_TOKEN(state, token) {
    //   state.token = token;
    // },
    // SET_USER(state, user) {
    //   state.user = user;
    // },
    // SET_OTP_VERIFICATION_ERROR(state, error) {
    //   state.otpVerificationError = error;
    // },
    // CLEAR_AUTH_DATA(state) {
    //   state.token = null;
    //   state.user = null;
    //   state.isAuthenticated = false;
    // },
  },

  actions: {
    async loginWithPassword({ commit }, data) {
      await axios
        .post(`${process.env.VUE_APP_API_ROOT}/employee/password-login/`, data)
        .then((response) => {
          if (response.data.status == 200) {
            commit("setLoggedInInfo", response.data.data);
            // commit("setPopupInfo", response.data.data);
          } else {
            console.error("Error Email & Password:", response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logout({ commit }) {
      commit("setLogout");
    },

    // async verifyOTP({ commit }, otpData) {
    //   try {
    //     const response = await axios.post(
    //       "https://api.talintelhr.com/api/employee/validate-otp/",
    //       otpData
    //     );
    //     var login_status = "";
    //     if (response.data.status === 200) {
    //       const employee_details = response.data.data.employee_details;
    //       commit("SET_USER", employee_details);
    //       commit("SET_isAuthenticated", true);
    //       commit("SET_OTP_VERIFICATION_ERROR", null);
    //       commit("SET_TOKEN", response.data.data.token);
    //       login_status = "200";
    //     } else {
    //       console.error("Error verifying OTP:", response.data.message);
    //       login_status = "400";
    //     }
    //   } catch (error) {
    //     console.error("Error verifying OTP:", error);
    //     login_status = "400";
    //   }
    //   return login_status;
    // },

    // logout({ commit }) {
    //   try {
    //     commit("CLEAR_AUTH_DATA");
    //     router.push("/");
    //   } catch (error) {
    //     console.error("Error logging out:", error);
    //   }
    // },
  },

  modules: {},
  plugins: [createPersistedState({
    // storage: {
    //   getItem: (key) => ls.get(key),
    //   setItem: (key, value) => ls.set(key, value),
    //   removeItem: (key) => ls.remove(key),
    // },
  })],
});
