import store from "@/store";


export const isTrustee = () => {

  console.log(store.getters.user.role);
  const role = store.getters.user.role;

  return role == "L0";
};

export const isAdmin = () => {

  console.log(store.getters.user.role);
  const role = store.getters.user.role;

  return role == "L1" || role == "L4";
};

export const isEmployee = () => {
  
  console.log(store.getters.user.role);
  const role = store.getters.user.role;

  return role == "L2";
};

export const isHR = () => {
  
  console.log(store.getters.user.role);
  const role = store.getters.user.role;

  return role == "L3";
};

