<template>
  <div>
   

    <div
      class="fixed inset-0 z-[1400] w-screen overflow-y-auto bg-black bg-opacity-50"
    >
      <div
        class="flex h-full justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <div
          class="relative transform bg-white rounded-lg border-gray-200 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[30%] lg:w-[350px]"
        >
          <form>
            <div class="bg-white">
              <div class="">
                <div cslass="mt-3 text-center sm:text-left">
                  <div class="relative p-4">
                    <div class="flex justify-end absolute -top-1 right-4">
                      <button type="button" @click="hidePopup" class=" text-xl">x</button>
                    </div>
                    <div class="text-sm" v-if="step === 1">
                      <h3
                        class="text-base font-semibold leading-6 text-gray-900 text-center"
                      >
                        HR Condition
                      </h3>
                      <p class="pt-3 text-md text-gray-600 leading-relaxed">
                        Our HR conditions ensure equal opportunity and fair
                        treatment, covering employment types, conduct
                        expectations, compensation, leave policies, and
                        performance management. We prioritize workplace safety
                        and employee well-being, with clear procedures for
                        conflict resolution and termination. Compliance with
                        these policies is mandatory for all employees, fostering
                        a harmonious and productive work environment.
                      </p>
                    </div>
                    <div class="text-sm" v-else-if="step === 2">
                      <h3
                        class="text-base font-semibold leading-6 text-gray-900 text-center"
                      >
                        Leave Condition
                      </h3>
                      <p class="pt-3 text-md text-gray-600 leading-relaxed">
                        Our leave conditions outline entitlements for vacation,
                        sick, parental, and bereavement leave, with clear
                        request and approval procedures. Eligibility criteria
                        are defined, including accrual, usage, and carryover
                        requirements. Flexibility is offered when possible, with
                        support provided during times of need. Compliance with
                        these conditions ensures fairness and consistency across
                        the organization.
                      </p>
                    </div>
                    <div class="text-sm" v-else-if="step === 3">
                      <h3
                        class="text-base font-semibold leading-6 text-gray-900 text-center"
                      >
                        Terms & Condition
                      </h3>
                      <p class="pt-3 text-md text-gray-600 leading-relaxed">
                        Our Terms and Conditions outline the agreement between
                        users and our company, covering usage, content, privacy,
                        and intellectual property rights. We reserve the right
                        to update these terms, with changes communicated to
                        users. Violation may lead to account suspension or
                        termination. The terms aim to ensure a safe and
                        respectful online environment for all users.
                      </p>
                      <div class="mt-4 w-full">
                        <input
                          type="checkbox"
                          id="agree1"
                          v-model="isChecked1"
                        />
                        <label for="agree1" class="text-md text-gray-500">
                          I Read the condition</label
                        >
                      </div>
                      <div class="mt-4">
                        <input
                          type="checkbox"
                          id="agree2"
                          v-model="isChecked2"
                        />
                        <label for="agree2" class="text-md text-gray-500">
                          I agree the condition</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 rounded-lg"
            >
              <button
                v-if="step < 3"
                type="button"
                @click="nextStep"
                class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset bg-[#4f46e5] sm:mt-0 sm:w-auto"
              >
                Next
              </button>
              <button
                v-else-if="isChecked1 && isChecked2"
                type="button"
                @click="submitForm"
                class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset bg-[#4f46e5] sm:mt-0 sm:w-auto"
              >
                Submit
              </button>
              <button
                v-if="step > 1 && step < 4"
                type="button"
                @click="prevStep"
                class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset bg-white sm:mt-0 sm:w-auto mx-3"
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      isPopupVisible: false,
      step: 1,
      isChecked1: false,
      isChecked2: false,
    };
  },

  created(){
    this.isPopupVisible = true;
  },

  methods: {
    showPopup() {
      this.isPopupVisible = true;
      this.step = 1;
      this.isChecked1 = false;
      this.isChecked2 = false;
    },
    hidePopup() {
      // this.$emit('closeHidePopup');
      this.isPopupVisible = false;
    },
    nextStep() {
      if (this.step < 3) {
        this.step += 1;
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    submitForm() {
      this.hidePopup();
    },
  },
};
</script>

<style scoped></style>
