<template>
    <div>
        <PopUp /> 
    </div>
   
</template>

<script>
import PopUp from "../components/PopUp.vue";
export default {
  name: "TestPopup",
  components:{
    PopUp,
  }
}
</script>

<style >
</style>